import axios from "axios";
import { handleError } from "./error_handler/error_handler";

//const url = "http://localhost:44952";
const url = "";
const baseUrl = url + "/api/";



const get = (url) => {
    return axios
        .get(baseUrl + url)
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            const message = handleError(err)
            throw new Error(message);
        });
}


const update = (url, newObject) => {
    return axios
        .patch(baseUrl + url, newObject)
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            const message = handleError(err)
            throw new Error(message);
        });
}


const create = (url, newObject) => {
    return axios
        .post(baseUrl + url, newObject)
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            const message = handleError(err)
            throw new Error(message);
        });
}


const api = {
    get,
    update,
    create
};

export default api;