import { useEffect, useState } from "react";
import api from "./api/api";
import './admin.css';
import { Loader } from "./home";
import { useInput } from "./hooks/use_input";

export default function Admin() {
    const [invitees, setInvitees] = useState();
    const [busy, setBusy] = useState(false);
    const [status, setStatus] = useState(null);
    const searchProps = useInput('');

    const [searchResult, setSearchResult] = useState([])

    const onSearch = (e) => {
        searchProps.onChange(e)
        const { target: { value } } = e;
        const newSearch = invitees.filter(invitee => invitee.name?.toLowerCase()?.includes(value?.toLowerCase()))
        setSearchResult(newSearch);
    }

    const statusMessageHandler = (message, success) => {
        setStatus({ success, message });
        setTimeout(() => {
            setStatus(null)
        }, 5000);
    }
    const onEdit = (id, name, table, phone, email, scheduledTime) => {
        const patch = [
            {
                path: '/name',
                value: name,
                op: 'add'
            },
            {
                path: '/tableNo',
                value: table || 0,
                op: 'add'
            },
            {
                path: '/phoneNumber',
                value: phone,
                op: 'add'
            },
            {
                path: '/email',
                value: email,
                op: 'add'
            },
            {
                path: '/scheduledTime',
                value: scheduledTime,
                op: 'add'
            }
        ]
        setBusy(true);
        api.update('invitees/' + id, patch)
            .then(data => {
                const update = invitees.map(x => x.id === data.id ? data : x);
                const updateSearch = searchResult.map(x => x.id === data.id ? data : x);
                setInvitees(update);
                setSearchResult(updateSearch)
                setBusy(false);
            })
            .catch(err => {
                console.log(err.message);
                statusMessageHandler(err.message);
                setBusy(false);
            })
    }
    useEffect(() => {
        setBusy(true);
        api.get('invitees')
            .then(data => {
                setInvitees(data)
                setSearchResult(data)
                setBusy(false);
            })
            .catch(err => {
                console.log(err.message);
                setBusy(false);
            })
    }, [])
    return (
        <>
            {busy && <Loader />}
            <div>
                {invitees && <p className='invited'>{invitees?.length} invited guests</p>}
                <p className='search'><input {...searchProps} onChange={onSearch} placeholder='Search name' /></p>
            </div>
            <Invitees invitees={searchResult} onEdit={onEdit} />
        </>
    );
}

const Invitees = ({ invitees, onEdit }) => {
    return (
        <table>
            <thead>
                <tr>
                    <th>Index</th>
                    <th>Id</th>
                    <th>Name</th>
                    <th>Table</th>
                    <th>Phone</th>
                    <th>Email</th>
                    <th>Scheduled Time</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                {invitees?.map((invitee, index) => <Invitee key={invitee.id} invitee={invitee} onEdit={onEdit} index={index + 1} />)}
            </tbody>
        </table>
    );
}

const Invitee = ({ invitee, index, onEdit }) => {

    const nameProps = useInput(invitee?.name || '', 'text');
    const tableProps = useInput(invitee?.tableNo || '', 'text', true);
    const emailProps = useInput(invitee?.email || '', 'email');
    const phoneProps = useInput(invitee?.phoneNumber || '', 'text');
    const [scheduledTime, setScheduledTime] = useState(invitee.scheduledTime);
    const values = ['None', 'Three', 'Seven'];
    const onClick = () => {
        onEdit(invitee.id, nameProps.value, tableProps.value, phoneProps.value, emailProps.value, scheduledTime);
    }
    const onChange = (e) => {
        setScheduledTime(e.target.value);
    }
    return (
        <tr>
            <td data-column="Index">{index}</td>
            <td data-column="Index">{invitee.id}</td>
            <td data-column="Name"> <input {...nameProps} /></td>
            <td data-column="Table"> <input {...tableProps} /></td>
            <td data-column="Phone"> <input {...phoneProps} /></td>
            <td data-column="Email"> <input {...emailProps} /></td>
            <td data-column="Scheduled Time">
                <select value={scheduledTime} onChange={onChange}>
                    {values.map((value) => <option key={value} value={value}>{value}</option>)}
                </select>
            </td>
            <td data-column="Action"><button type='button' onClick={onClick}>Edit</button></td>
        </tr>
    );
}