import { Route, Switch } from 'react-router';
import Admin from './admin';
import './app.css';
import Home from './home';

export default function App() {
  return (
    <Switch>
      <Route exact path='/' component={Home} />
      <Route exact path='/admin' component={Admin} />
    </Switch>
  );
}